@import "variables";
@import "highlights";
@import "fonts";

* {
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: $font;
    font-size: 16px;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(70, 70, 70, 0.1);
    background-color: white;
}

.no-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.select {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    -o-user-select: text;
    user-select: text;
}

a {
    text-decoration: underline;
    text-decoration-skip-ink: none;
    -webkit-text-decoration-color: blue;
    text-decoration-color: blue;
    color: inherit;
    &:hover {
        color: blue;
    }
    &.hidden-link {
        text-decoration: none!important;
        &:hover {
            color: inherit!important;
        }
    }
}

a[href^="http://"],
a[href^="https://"] {
    text-decoration-style: double;
    -webkit-text-decoration-style: double;
}

header, footer, h1, h2, h3, h4, h5, h6, dt, .info-title {
    a {
        text-decoration: none;
        position: relative;
        display: inline-block;
    }
    .header-tip {
        visibility: hidden;
        font-family: $font;
        width: auto;
        display: inline-block;
        text-align: center;
        z-index:100;
        position: absolute;
        background-color: #565656;
        border: 2px solid white;
        color: white;
        border-radius: 14px;
        padding: 0 0.5em;
        top: 2em;
        left: 0px;
        font-size: 50%;
        white-space: nowrap;
    }
    a:hover .header-tip {
        visibility: visible;
    }
    .header-divider {
        width: 0.25em;
        display: inline-block;
    }
}

h1 {
    font-size: 220%;
    font-family: $fontBold;
    font-weight: normal;
    margin-bottom: 10px;
}

h2 {
    font-size: 1.4em;
    font-family: $fontBold;
    font-weight: normal;
    margin-top: 1.2em;
    margin-bottom: 0.8em;
}

h3, .info-title {
    font-size: 1.2em;
    font-family: $fontBold;
    font-weight: normal;
    margin-top: 1em;
    margin-bottom: 0.8em;
}

h4, h5 {
    font-family: $fontBold;
    font-weight: normal;
}

p {
    margin: 15px 0;
    line-height: 1.4em;
}

td {
    line-height: 1.4em;
    > p, > dl {
        margin: 0;
    }
    > p + p {
        margin-top: 0.35em;
    }
}

b, strong {
    font-family: $fontBold;
    font-weight: normal;
    font-style: normal;
}

em {
    font-family: $fontItalic;
    font-weight: normal;
    font-style: normal;
}

ul {
    list-style: disc;
    margin: 0;
    padding: 0;
    ul {
        list-style: circle;
        ul {
            list-style: square;
        }
    }
}

ul, ol {
    li {
        margin-left: 1em;
        margin-top: 0;
        margin-bottom: 0;
        line-height: 1.4em;
    }
}

ol {
    margin: 0;
    padding: 0;
    counter-reset: ol_list_item;
    > li {
        list-style: none;
        margin-left: 1.6em;
        &::before {
            display: inline-block;
            content: counter(ol_list_item) ".";
            counter-increment: ol_list_item;
            font-family: $fontMono;
            width: 4em;
            margin-left: -4.3em;
            padding-right: .3em;
            text-align: right;
            float: left;
        }
        > ol li {
            margin-left: 1.4em;
        }
    }
}

dl {
    dd, dt {
        line-height: 1.4em;
    }
    dt {
        font-family: $fontBold;
        margin-bottom: .3em;
    }
    dd {
        margin-bottom: .6em;
        margin-left: 1.4em;
        p:first-of-type {
            margin-top: 0;
        }
    }
}

ul + blockquote, ul + table, ul + div>div.image,
ol + blockquote, ol + table, ol + div>div.image,
dl + blockquote, dl + table, dl + div>div.image,
blockquote + ul,
blockquote + ol,
blockquote + dl,
blockquote + table,
table + ul,
table + ol,
table + dl,
table + h2,
table + h3,
table + blockquote {
    margin-top: 1em !important;
}

pre, code, cite {
    font-family: $fontMono;
}

p, li, dd, dt, td, th {
    // literal code samples inside text elements
    code {
        // same as _highlights.scss > code.highlighter-rouge
        background-color: scale-color(adjust-hue($highlightCodeColor, 10deg), $lightness: 80%);
    }
}

pre {
    line-height: 1.4em;
    font-size: 90%;
    overflow-x: scroll;
    white-space: pre;
}

nav.icons {
    font-family: $fontIcon;
}

::-moz-selection {
    background-color: rgba($defaultColor, 0.2) ;
}
::selection {
    background-color: rgba($defaultColor, 0.2);
}

// news

.news a:hover, a.news {
    color: $newsColor;
}

.news {
    -webkit-tap-highlight-color: rgba($newsColor, 0.1);
    ::-moz-selection {
        background-color: rgba($newsColor, 0.2) ;
    }
    ::selection {
        background-color: rgba($newsColor, 0.2);
    }
    a {
        -webkit-text-decoration-color: $newsColor;
        text-decoration-color: $newsColor;
    }
    #secondary {
        background-color: $newsColor;
        border-right: 1px solid $newsColor;
        a {
            color: $newsColor;
            &:hover {
                color: black;
            }
        }
        .current {
            background-color: $newsColor;
            border-right: 10px solid $newsColor;
        }
    }
    hr {
        border: 1px solid $newsColor;
    }
    article {
        margin-bottom: 15px;
        border-bottom: 2px solid $newsColor;
        &:last-of-type {
            border-bottom:none;
        }
    }
    .fourOFour {
        color: $newsColor;
    }
    table, table th, table td {
        border: 1px solid $newsColor;
    }
    table th {
        background-color: rgba($newsColor, .3);
    }
    h1 {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    #news-titles {
        margin-left: 20px;
    }
}

// written date

.written-date {
    font-style: $fontMono;
    font-size: 80%;
    clear: both;
    margin-bottom: 20px;
}
#home-page .posts.news {
    border: 10px solid $newsColor;
    border-radius: 5px;
    padding: 10px;
}

// documentation

.documentation a:hover, a.documentation{
    color: $documentationColor;
}

.documentation {
    -webkit-tap-highlight-color: rgba($documentationColor, 0.1);
    ::-moz-selection {
        background-color: rgba($documentationColor, 0.2) ;
    }
    ::selection {
        background-color: rgba($documentationColor, 0.2);
    }
    a {
        -webkit-text-decoration-color: $documentationColor;
        text-decoration-color: $documentationColor;
    }
    #secondary {
        background-color:  $documentationColor;
        border-right:  1px solid $documentationColor;
        a {
            color: $documentationColor;
            &:hover {
                color: black;
            }
        }
        .current {
            background-color: $documentationColor;
            border-right: 10px solid $documentationColor;
        }
    }
    hr {
        border: 1px solid $documentationColor;
        margin: 2em 0;
    }
    .fourOFour {
        color: $documentationColor;
    }
    table, table th, table td {
        border: 1px solid rgba($documentationColor, .5);
    }
    tbody + tbody {
        border-top: 2px solid rgba($documentationColor, .65);
    }
    th {
        background-color: rgba($documentationColor, .3);
    }
}

// how-tos

.how-tos a:hover, a.how-tos{
    color: $how-tosColor;
}

.how-tos {
    -webkit-tap-highlight-color: rgba($how-tosColor, 0.1);
    ::-moz-selection {
        background-color: rgba($how-tosColor, 0.2) ;
    }
    ::selection {
        background-color: rgba($how-tosColor, 0.2);
    }
    a {
        -webkit-text-decoration-color: $how-tosColor;
        text-decoration-color: $how-tosColor;
    }
    #secondary {
        background-color:  $how-tosColor;
        border-right:  1px solid $how-tosColor;
        a {
            color: $how-tosColor;
            &:hover {
                color: black;
            }
        }
        .current {
            background-color: $how-tosColor;
            border-right: 10px solid $how-tosColor;
        }
    }
    hr {
        border: 1px solid $how-tosColor;
    }
    .fourOFour {
        color: $how-tosColor;
    }
    table, table th, table td {
        border: 1px solid $how-tosColor;
    }
    table th {
        background-color: rgba($how-tosColor, .3 );
    }
}

// extensions

.extensions a:hover, a.extensions{
    color: $extensionsColor;
}

.extensions {
    -webkit-tap-highlight-color: rgba($extensionsColor, 0.1);
    ::-moz-selection {
        background-color: rgba($extensionsColor, 0.2) ;
    }
    ::selection {
        background-color: rgba($extensionsColor, 0.2);
    }
    a {
        -webkit-text-decoration-color: $extensionsColor;
        text-decoration-color: $extensionsColor;
    }
    #secondary {
        background-color:  $extensionsColor;
        border-right:  1px solid $extensionsColor;
        a {
            color: $extensionsColor;
            &:hover {
                color: black;
            }
        }
        .current {
            background-color: $extensionsColor;
            border-right: 10px solid $extensionsColor;
        }
    }
    hr {
        border: 1px solid $extensionsColor;
    }
    .fourOFour {
        color: $extensionsColor;
    }
    table, table th, table td {
        border: 1px solid $extensionsColor;
    }
    table th {
        background-color: rgba($extensionsColor, .3 );
    }
}

// building-tools

.building-tools a:hover, a.building-tools {
    color: $building-toolsColor;
}

.building-tools {
    -webkit-tap-highlight-color: rgba($building-toolsColor, 0.1);
    ::-moz-selection {
        background-color: rgba($building-toolsColor, 0.2) ;
    }
    ::selection {
        background-color: rgba($building-toolsColor, 0.2);
    }
    a {
        -webkit-text-decoration-color: $building-toolsColor;
        text-decoration-color: $building-toolsColor;
    }
    #secondary {
        background-color:  $building-toolsColor;
        border-right:  1px solid $building-toolsColor;
        a {
            color: $building-toolsColor;
            &:hover {
                color: black;
            }
        }
        .current {
            background-color: $building-toolsColor;
            border-right: 10px solid $building-toolsColor;
        }
    }
    hr {
        border: 1px solid $building-toolsColor;
    }
    .fourOFour {
        color: $building-toolsColor;
    }
    table, table th, table td {
        border: 1px solid $building-toolsColor;
    }
    table th {
        background-color: rgba($building-toolsColor, .3 );
    }
}

// store

.store a:hover, a.store {
    color: $storeColor;
}

.store {
    -webkit-tap-highlight-color: rgba($storeColor, 0.1);
    ::-moz-selection {
        background-color: rgba($storeColor, 0.2) ;
    }
    ::selection {
        background-color: rgba($storeColor, 0.2);
    }
    a {
        -webkit-text-decoration-color: $storeColor;
        text-decoration-color: $storeColor;
    }
    #secondary {
        background-color:  $storeColor;
        border-right:  1px solid $storeColor;
        a {
            color: $storeColor;
            &:hover {
                color: black;
            }
        }
        .current {
            background-color: $storeColor;
            border-right: 10px solid $storeColor;
        }
    }
    hr {
        border: 1px solid $storeColor;
    }
    .fourOFour {
        color: $storeColor;
    }
    table, table th, table td {
        border: 1px solid $storeColor;
    }
    table th {
        background-color: rgba($storeColor, .3 );
    }
}

// education

.education a:hover, a.education {
    color: $educationColor;
}

.education {
    -webkit-tap-highlight-color: rgba($educationColor, 0.1);
    ::-moz-selection {
        background-color: rgba($educationColor, 0.2) ;
    }
    ::selection {
        background-color: rgba($educationColor, 0.2);
    }
    a {
        -webkit-text-decoration-color: $educationColor;
        text-decoration-color: $educationColor;
    }
    #secondary {
        background-color:  $educationColor;
        border-right:  1px solid $educationColor;
        a {
            color: $educationColor;
            &:hover {
                color: black;
            }
        }
        .current {
            background-color: $educationColor;
            border-right: 10px solid $educationColor;
        }
    }
    hr {
        border: 1px solid $educationColor;
    }
    .fourOFour {
        color: $educationColor;
    }
    table, table th, table td {
        border: 1px solid $educationColor;
    }
    table th {
        background-color: rgba($educationColor, .3);
    }
}

// forum

.forum a:hover, a.forum {
    color: $forumColor;
}

.forum a {
    -webkit-text-decoration-color: $forumColor;
    text-decoration-color: $forumColor;
}

a.news:hover,
a.documentation:hover,
a.extensions:hover,
a.building-tools:hover,
a.store:hover,
a.education:hover,
a.forum:hover,
a.how-tos:hover  {
    color:inherit!important;
}

li.news,
li.documentation,
li.extensions,
li.building,
li.store,
li.education,
li.forum,
li.how:hover  {
    line-height: 1.4em;
}

.continue-reading {
    margin-top: 15px;
    margin-bottom: 15px;
}

// page-wrapper

.page-wrapper {
    max-width: 700px;
    margin: 8px;
    padding:0 10px 10px 40px;
}

// image

img {
    max-width: 100%;
    margin-bottom: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.image {
    margin-left: 20px;
    display: inline-block;
}

.image-caption {
    font-family: $fontItalic;
    font-size: 90%;
    margin-bottom: 10px;
    text-align: center;
}

// navigation hover helpers

#secondaryCatchAll {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: none;
}

#secondary {
    width: 10px;
    padding: 10px;
    padding-bottom: 200px;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; // for Firefox
    background-color: black;
    border-right: 1px solid black;
    z-index: 1000;
    @extend .no-select;
    .navitems {
        display: none;
    }
    &.slideout {
        width: auto;
        max-width: 60%;
        background-color: white;
        .navitems {
            display: block;
        }
        a.hamburgerfons {
            display: none;
        }
    }
    a {
        text-decoration: none;
    }
    a.hamburgerfons {
        color: white!important;
        text-align: center;
        font-size: 15px;
        width: 30px;
        margin-left: -10px;
        display: block;
    }
    ul  {
        display: block;
        list-style: none;
        li {
            margin-left: 0;
            font-family: $fontBold;
            position: relative;
            ul {
                margin-left: 15px;
                li {
                    font-family: $font;
                    ul li {
                        font-size: 85%;
                        ul li {
                            font-size: 100%;
                        }
                    }
                }
            }
        }
    }
    .menutitle {
        font-family: $fontBold;
        font-size: 130%;
    }
    .navtitle:not(:first-child) {
        margin-top: 20px;
    }
    a.current {
        font-family: $fontBold;
        text-decoration: none;
        display: block;
        width: 100%;
        border-right: 10px solid black;
        background-color: black;
        color: white;
        padding-left: 5px;
        margin-left: -5px;
        &:hover {
            color: white;
        }
    }
    .hide-section {
        display: none;
    }
    a.showhide {
        color: rgb(73, 73, 73);
        display: block;
        position: absolute;
        top: 0px;
        margin-left: -1em;
        cursor: pointer;
        font-family: $fontBold, $fontIcon;
    }
    &::-webkit-scrollbar {
        display: none;  // Safari and Chrome
    }
}

// infotips (glossary)

span.info {
    position: relative;
    text-decoration: none;
    border-bottom: dotted 2px $infoTipColor;
    .tip {
        color: black;
        background-color: mix($infoTipColor, #FFF, 20%);
        border: 1px solid $infoTipColor;
        border-radius: $boxRadius;
        visibility: hidden;
        font-family: $font;
        width: 16em;
        max-width: 300px;
        display: inline-block;
        z-index: 100;
        position: absolute;
        padding: 0.5em;
        top: 1.5em;
        left: 50%;
        transform: translateX(-50%);
        font-size:90%;
        line-height: 120%;
        height: auto;
        @extend .no-select;
        code {
            background-color: mix($infoTipColor, #FFF, 50%);
        }
    }
    &:hover::after {
        content:  "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: -10px;
        left: 0;
    }
    &:hover .tip {
        visibility: visible;
    }
    .hidden-link {
        position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          color: transparent;
          user-select: none;
          z-index: 1000;
    }
}

// buttons

.buy-button {
    text-decoration: none;
    display: inline-block;
    padding: 5px 15px;
    font-size: 1.1em;
    border-radius: 40px;
    background-color: black;
    border: 1px solid black;
    color:white;
    margin-bottom: 10px;
    @extend .no-select;
    &:hover {
        background-color: white;
        color:blue;
    }
    &.small {
        font-size: .8em;
        padding: 3px 10px;
        margin-bottom: 0;
    }
}

// search

#search {
    display: inline-block;
    margin: 0;
    width: 160px;
    input[type=text] {
        -moz-appearance:button;
        -webkit-appearance:button;
        appearance: none;
        display: inline-block;
        background-color: transparent;
        font-family: $font;
        font-size: .5em;
        color: blue;
        margin: 0;
        border: 1px solid blue;
        padding: 4px 10px 4px 28px;
        border-radius: 40px;
        opacity: 0;
        width: 122px;
        margin-left: -28px;
        vertical-align: middle;
        &:hover {
            opacity: 1;
            font-family: $font;
        }
        &:focus {
            opacity: 1;
            outline: none;
            font-family: $font;
        }
        &::placeholder {
            color: blue;
            font-family: $font;
            text-indent:15px;
        }
    }
    button {
        -moz-appearance:button;
        -webkit-appearance:button;
        appearance: none;
        margin: 0;
        padding: 0;
        width: 21px;
        text-align: right;
        vertical-align: middle;
        font-family: $fontIcon;
        font-size:  1em;
        color: blue;
        background-color: transparent;
        pointer-events:none;
        border: none;
        display: inline-block;
    }
}

.search-query {
    font-family: $font;
    text-decoration: underline;
}

mark {
    background-color: transparent;
    border-top: .2em double #ffe721;
    border-bottom: .2em double #ffe721;
    color: inherit;
}

// icon

.icon {
    pointer-events:none;
    @extend .no-select;
}

// header

header {
    @extend .no-select;
    .logo {
        line-height: 1em;
        font-family: $fontNarrow;
        font-size: 9.8vw;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .logo-slide {
        transition: margin .5s ease-in;
        &:hover, &:focus {
            text-overflow: none;
            margin-left: -4.1em;
        }
        &:hover .icon-to-hide {
            opacity: 0;
        }
        .icon-to-hide {
            transition: opacity .3s ease-in;
        }
    }
    nav {
        padding-left: 3px;
        font-size: 2em;
        line-height: .75em;
    }
    h1 {
        @extend .select;
    }
}

// main

#main {
    margin-top: 30px;
    min-height: 400px
}

.clearer {
    clear: both;
}

// footer

footer {
    margin-top: 100px;
    line-height: 1em;
    @extend .no-select;
    .col {
        width: 49%;
        display: inline-block;
        vertical-align: top
    }
    .icon {
        font-size: 5em;
        line-height: .75em;
    }
    ul {
        list-style: none;
        li {
            margin-left: 0;
        }
    }
    #prevpage {
        float: left;
        margin-bottom: 15px;
    }
    #nextpage {
        float: right;
        margin-bottom: 15px;
    }
}

// eula

.eula-link {
    margin: 15px 0;
    line-height: 1.25em;
}

#eula #main a {
    text-decoration: none;
}

// code download link

.scriptReference {
    display: block;
    font-size: 90%;
    padding: 0px 2px 5px 20px;
}

.downloadlink {
    margin-bottom: 15px;
}
.touch .downloadlink {
    display: none;
}

// 404

.fourOFour {
    font-size:35vw;
    font-family:$fontBold;
    @extend .no-select;
}

// red

.red {
    color: red;
}

// splash front page

.splash-subtitle {
    font-size: 220%;
    font-family: $fontBold;
    margin-top: 30px;
    margin-bottom: 15px;
}

.splash-icon {
    line-height: 1.1em;
    margin: 0 auto;
    margin-top: -0.3em;
    font-size: 40vw;
}

// navigation splash front page

.navigation-splash {
    font-size: 1em;
    text-align: center;
    a {
        display: inline-block;
        text-decoration: none;
        text-align: center;
        width: 30%;
    }
    .icon {
        font-family: "RoboType-Icons", Helvetica;
        width: 1.25em;
        width: 100%;
        display: inline-block;
        text-align: center;
        font-size: 6em;
        line-height: 1em;
    }
}

// forms

form {
    margin-top: 20px;
    margin-bottom: 15px;
    fieldset {
        border: none;
        padding: 0;
        margin: 0;
    }
    label {
        display: block;
        em {
            color: red;
            font-weight: normal;
            font-style: normal;
        }
        &.error {
            color: red;
            margin-bottom: 7px;
        }
    }
    input {
         box-sizing: border-box;
    }
    input, textarea {
        -moz-appearance:button;
        -webkit-appearance:button;
        appearance: none;
    }
    select {
        // does not style well, keep it as the OS provides
        margin: 5px 5px 5px 0px;
    }
    input, textarea {
        width: 100%;
        border: 1px solid lightgray;
        border-radius: 3px;
        font-size: 100%;
        padding: 5px;
        line-height: 1.5em;
        margin: 5px 0 10px 0px;
        font-family: $font;
        &:hover,
        &:focus {
            -moz-appearance:button;
            -webkit-appearance:button;
            appearance: none;
            outline: none;
            border: 1px solid black;
            font-family: $font;
        }
    }
    textarea {
        height: 6em;
    }
    input[type="submit"] {
        width: auto;
        padding-left: 15px;
        padding-right: 15px;
        background-color: white;
        &:active {
            background-color: black;
            color: white;
        }
    }
}

// note, warning, todo, seealso, tip

blockquote {
    border-left: 10px solid #DDD;
    margin: $boxMargin;
    padding: $boxPadding*0.5 $boxPadding;
    background-color: #EEE;
    &.note,
    &.warning,
    &.todo,
    &.seealso,
    &.tip,
    &.asCode {
        padding: $boxPadding;
        border-radius: $boxRadius;
        border: 1px solid;
        pre code {
            background-color: transparent;
        }
        p:last-child {
            margin-bottom: 0.3em;
        }
        ul:first-child {
            margin-top: 0.6em;
        }
    }
    &.note {
        background-color: rgba($noteColor, 0.2);
        border-color: $noteColor;
        code {
            background-color: rgba($noteColor, 0.3);
        }
        &::before {
            content: "Note";
            // color: $noteColor;
            font-family: $fontBold;
            display: block;
        }
    }
    &.warning {
        background-color: rgba($warningColor, 0.2);
        border-color: $warningColor;
        code {
            background-color: rgba($warningColor, 0.2);
        }
        &::before {
            content: "Warning";
            // color: $warningColor;
            font-family: $fontBold;
            display: block;
        }
    }
    &.todo {
        background-color: rgba($todoColor, 0.2);
        border-color: $todoColor;
        code {
            background-color: rgba($todoColor, 0.2);
        }
        &::before {
            content: "To-Do";
            // color: $todoColor;
            font-family: $fontBold;
            display: block;
        }
    }
    &.seealso {
        background-color: rgba($seealsoColor, 0.2);
        border-color: $seealsoColor;
        code {
            background-color: rgba($seealsoColor, 0.2);
        }
        &::before {
            content: "See Also";
            // color: $seealsoColor;
            font-family: $fontBold;
            display: block;
        }
    }
    &.tip {
        background-color: rgba($tipColor, 0.2);
        border-color: $tipColor;
        code {
            background-color: rgba($tipColor, 0.2);
        }
        &::before {
            content: "Tip";
            // color: $tipColor;
            font-family: $fontBold;
            display: block;
        }
    }
    &.asCode {
        p {
            white-space: pre;
            margin: 0;
            &:not(:first-child) {
                margin-top: 1.4em;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        margin: 0;
        font-family: $fontMono;
        color: $highlightConsoleColor;
        background-color: rgba($highlightConsoleColor, 0.2);
        border-color: $highlightConsoleColor;
    }
    &.no-title {
        p {
            margin: 0;
            &:not(:first-child) {
                margin-top: 1.4em
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        &::before {
            content: none;
            display: none;
        }
    }
    &.version-badge {
        position: relative;
        display: inline-block;
        color: rgb(255, 72, 197);
        font-size: 80%;
        font-weight: bold;
        background: #f8ff35;
        border: none;
        padding: 10px 16px;
        margin: 0;
        margin-left: 5px;
        float: right;
        -webkit-clip-path: polygon(100% 0%, 95% 50%, 100% 100%, 10% 100%, 0% 50%, 10% 0%);
        clip-path: polygon(100% 0%, 95% 50%, 100% 100%, 5% 100%, 0% 50%, 5% 0%);
        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }

        p {
            padding: 5px;
            margin: 0;
        }
    }
}

// table of contents

#markdown-toc {
    margin: $boxMargin;
    padding: $boxPadding;
    border: 1px dashed #999;
    border-radius: $boxRadius;
    &::before {
        content: "Table of Contents ▾";
        font-family: $fontBold;
        display: block;
    }
}

.toc-collapse {
    &::before {
        content: "Table of Contents ▸" !important;
    }
}

.class-content {
    #markdown-toc {
        &::before {
            content: "Table of Class Contents ▾";
        }
    }
    .toc-collapse {
        &::before {
            content: "Table of Class Contents ▸" !important
        }
    }
}

// tables

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 5px;
    margin-bottom: 5px;
    th {
        text-align: left;
        background-color: #DDD;
        padding: 5px;
    }
    td {
        text-align: left;
        padding: 5px;
        ul > li {
            line-height: 1.4em;
        }
        code {
            // same as _highlights.scss > code.highlighter-rouge
            background-color: scale-color(adjust-hue($highlightCodeColor, 10deg), $lightness: 80%);
            padding: 1px 3px;
            border-radius: 3px;
        }
    }
}

table, table th, table td {
    border: 1px solid #AAA;
}

// sorting

.sorter-item {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    .sorter-item-inner {
        width: 100%;
        display: flex;
    }
    .sorter-item-info,
    .sorter-item-title,
    .sorter-item-single-info {
        display: inline-block;
    }
    .sorter-item-info {
        flex: 0 0 300px; // don't grow, don't shrink, stay at 360px width
        .sorter-item-description {
            font-family: $fontBold;
            padding: 10px 0px 10px 10px;
            color: black;
        }
    }
    .sorter-item-single-info {
        flex: 0 0 150px; // don't grow, don't shrink, stay at 360px width
        .sorter-item-author,
        .sorter-item-tags,
        .sorter-item-section {
            display: block;
            vertical-align: top;
            width: auto;
        }
    }
    .sorter-item-section {
        a {
            text-decoration: none;
        }
    }
    .sorter-item-description {
        @extend .no-select;
        color: gray;
        font-family: $fontItalic;
    }
    .sorter-item-subinfo {
        display: flex;
    }
    .sorter-item-title {
        flex-grow: 2;
        font-family: $fontBold;
        font-size: 130%;
        padding: 10px 10px 10px 0px;
        // margin-bottom: 10px;
        // -webkit-hyphens: auto;
        // -moz-hyphens: auto;
        // hyphens: auto;
        a {
            text-decoration: none;
            vertical-align: top;
        }
    }
    .sorter-item-icon {
        display: inline;
        width: 50px;
        margin-right: 5px;
    }
    .sorter-item-author,
    .sorter-item-tags,
    .sorter-item-section {
        padding: 10px 0px 10px 10px;
    }
    .sorter-item-author,
    .sorter-item-tags,
    .sorter-item-section {
        display: inline-block;
        vertical-align: top;
        width: 50%;
    }
    .sorter-item-tags {
        text-align: right;
    }
    .sorter-item-section,
    .sorter-item-author {
        font-family: $fontBold;
    }
    .sorter-item-tags,
    .sorter-item-author {
        font-size: 90%;
        padding-bottom: 10px;
    }
    .sorter-item-tags {
        a {
            text-decoration: none;
        }
        .store {
            font-family: $fontItalic;
        }
    }
}

.sorter-checkboxes {
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: center;
    @extend .no-select;
    fieldset {
        border: none;
    }
    .developers,
    .stores {
        display: none;
    }
}

.sorter-checkbox {
    span {
        display: inline-block;
        width: auto;
        padding: 3px 10px;
        border: 1px solid rgb(190, 190, 190);
        border-radius: 20px;
        margin-bottom: 10px;
        &:hover {
            filter: grayscale(100%);
        }
    }
    input {
        display: none;
    }
    input:checked + span {
        color: white;
        background-color: black!important;
        border: 1px solid black!important;
    }
}

.sorter-item-color {
    display: flex;
    height: 4px;
    width: 100%;
    position: absolute;
    border-radius: 5px;
    top: 0;
    z-index: -100;
    div {
        flex: 1;
        position: relative;
    }
    div:first-of-type {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    div:last-of-type {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    &.item-no-tags {
        border: 1px solid lightgray;
        width: calc(100% - 2px);
    }
}

// sorting controls

.sorting-controls {
    width: 100%;
    text-align: right;
    font-size: 90%;
    line-height: 20px;
    color: #c5c5c5;
    @extend .no-select;
    label {
        margin-left: 20px;
        margin-bottom: 5px;
    }
    .control-title {
        margin-left: 40px;
        line-height: 20px;
    }
    .sorting-developer-switch,
    .sorting-store-switch {
        position: relative;
        display: inline-block;
        height: 20px;
        input {
            display:none;
            &:checked + .slider {
            background-color: #c5c5c5;;
            }
            &:checked + .slider:before {
                transform: translateX(16px);
            }
        }
        &:hover {
            cursor: pointer;
            color: gray;
        }
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        width: 36px;
        bottom: 0;
        background-color: #e3e3e3;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;
        &:before {
            position: absolute;
            content: "";
            height: 12px;
            width: 12px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 50%;
        }
    }
    .reset {
        border: 1px solid lightgray;
        padding: 2px 9px;
        border-radius: 26px;
        background-color: transparent;
        &:hover {
            cursor: pointer;
            border: 1px solid gray;
            color: gray;
        }
    }
}

// header links

.header-link {
  opacity: 0;
  text-decoration: none;
}

h2:hover .header-link,
h3:hover .header-link,
h4:hover .header-link,
h5:hover .header-link,
h6:hover .header-link,
dt:hover .header-link {
  opacity: 1;
}

.header-link i:after {
    content: "¶";
    position: absolute;
    right: -0.7em;
    top: -0.85em;
}

// tags

.tags {
    font-size: 85%;
    @extend .no-select;
    margin: 1em 0 1.5em 0;
    a {
        display: inline-block;
        border-radius: 20px;
        border: 1px solid gray;
        padding: 3px 10px;
        margin-right: 5px;
        margin-bottom: 5px;
        text-decoration: none;
        color: gray;
    }
}

// description

.page-description {
    @extend .no-select;
    color: gray;
    font-family: $fontItalic;

    p {
        margin-top: 0;
    }
}

// api

.api-docs {
    h2 {
        font-size: 150%;
    }
    .class {
        .name {
            color: $apiClassColor;
        }
        .class-content {
            // font-family: $fontMono;
            border-left: 5px solid rgba($apiClassBorderColor, .1);
            padding-left: 20px;
            .attribute {
                margin-left:-20px;
                padding-left: 17px;
                border-left: 6px solid rgba($apiClassBorderColor, .1);
            }
        }
    }
    .function {
        .name {
            color: $apiFunctionColor;
        }
        .function-content {
            // font-family: $fontMono;
            padding-left: 20px;
        }
    }
    .attribute {
        .name {
            color: $apiAttributeColor;
        }
        .attribute-content {
            padding-left: 20px;
        }
    }
    .description {
        .name {
            color: $apiDescriptionColor;
        }
        .description-content {
            padding-left: 20px;
        }
    }
    .inherit {
        margin-left: -20px;
        padding-left: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: center;
        font-size: 80%;
        color: $apiClassBorderColor;
        background-color: rgba($apiClassBorderColor, .1);
        .subclass {
            font-family: $fontBold;
        }
    }
    .arguments, .type-name {
        color: $apiArgumentColor;
    }
    .class, .function, .attribute, .description {
        margin-bottom: 30px;
    }
}

.class-content, .function-content,
.attribute-content, .description-content {
    pre {
        // same as _highlights.scss > code.highlighter-rouge
        background-color: scale-color(adjust-hue($highlightCodeColor, 10deg), $lightness: 80%);
        border-radius: 3px;
        padding: $boxPadding;
        margin: $boxMargin;
        overflow: auto;
        &::first-line {
            text-indent: -0.2em;
        }
    }
    code {
        background-color: scale-color(adjust-hue($highlightCodeColor, 10deg), $lightness: 80%);
        padding: 1px 3px;
        border-radius: 3px;
    }
}

// to be or not to be required

.red, .required, .no, {
    color: red;
}

.green, .not-required, .yes, .recommended {
    color: green;
}

.yellow, .maybe, .deprecated {
    color: rgb(255, 165, 51);
}

.green-bg {
    background-color: rgba(0, 255, 0, 0.3);
}
.yellow-bg {
    background-color: rgba(255, 165, 51, 0.3);
}
.red-bg {
    background-color: rgba(255, 0, 0, 0.3);
}

// system fonts

.systemfont {
    font-family: "San Francisco", "Helvetica Neue";
    font-weight: normal;
    font-style: normal;
}

// slides

.slides {
    margin: 0;
    padding: 0;
    list-style: none;
    display: none;
    .slide-item {
        margin: 0;
        padding: 0;
        height: 100%;
        img {
            margin: 0 auto;
            max-height: 100%;
        }
        .slide-item-caption {
            z-index: 1000;
            position: absolute;
            bottom: 5px;
            left: 50%;
            transform: translate(-50%, 0);
            max-width: 90%;
            background-color: white;
            box-shadow: 0px 0px 5px lightgray;
            border-radius: 40px;
            padding: 0 0.5em;
            font-family: $fontItalic;
            font-size: 90%;
            p {
                margin: 5px;
            }
        }
    }
    ul.slidesjs-pagination {
        list-style: none;
        text-align: center;
        margin: 5px 0;
        li {
            display: inline-block;
            margin: 0;
            padding: 0;
        }
        a {
            text-decoration: none;
            visibility: hidden;
            font-size: 0px;
            &:after {
                content: "●";
                color: lightgray;
                visibility: visible;
                font-size: 14px;
                padding: 5px;
            }
            &.active:after {
                color: black
            }
        }
    }
}



// modification date

.modification-date {
    font-style: $fontMono;
    font-size: 80%;
    clear: both;
    margin-top: 50px;
    text-align: right;
}

// prev next

.prev-next {
    padding-top: 30px;
    padding-bottom: 10px;
    a {
        text-decoration: none;
    }
    .next {
        float: right;
        &:after {
            content: " →";
            font-family: $fontIcon;
        }
    }
    .prev {
        float: left;
        &:before {
            content: "← ";
            font-family: $fontIcon;
        }
    }
}

[contenteditable]:focus {
    outline: 0px solid transparent;
}

// align

.align-right {
    float: right;
}

.align-left {
    float: left;
}

// drafts

.draft-bar {
    @extend .no-select;
    background-color: orange;
    color: white;
    padding: 10px;
    padding-left: 30px;
    min-height: 61px;
    margin-left: 20px;
    .draft-title {
        font-size: 140%;
    }
}

a.draft-source {
    text-decoration: none;
    font-family: $fontMono;
    margin-right: 20px;
}

// table header widths

.table-35-65
    {
    th:nth-child(1) {
        width: 35%;
        }
    th:nth-child(2) {
        width: 65%;
    }
}

// media queries

@media (min-width: 750px) {
    header .logo {
        font-size: 4.6em;
    }
    header nav {
        line-height: .75em;
    }
}

@media (max-width: 550px) {
    // blockquote {
    //     &.note,
    //     &.warning,
    //     &.todo,
    //     &.seealso,
    //     &.tip {
    //         width: 80%;
    //     }
    // }
    .sorter-item-inner,
    .sorter-item-info,
    .sorter-item-single-info,
    .sorter-item-author,
    .sorter-item-tags,
    .sorter-item-section,
    .sorter-item-description {
        display: block!important;
        width: auto!important;
        text-align: left!important;
    }

    .navigation-splash {
        a {
            width: 45%!important;
        }
    }
}

@media (max-width: 400px) {
    nav.icons {
        width: 90%;
        line-height: 1em;
    }
    #search {
        width: 120px;
        input[type=text] {
            width: 82px;
        }
    }
}

@import "simple-grid";

// BETA ribbon

.ribbon-wrapper {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: fixed;
    top: -4px;
    right: 0px;
    z-index: 90;
}

.ribbon {
    font-family: $fontBold;
    text-align: center;
    -webkit-transform: rotate(45deg);
    -moz-transform:    rotate(45deg);
    -ms-transform:     rotate(45deg);
    -o-transform:      rotate(45deg);
    position: relative;
    padding: 7px 0;
    left: -50px;
    top: 40px;
    width: 300px;
    background-color: rgba(255, 0, 0, 0.75);
    color: white;
    @extend .no-select;
}

// strike-through

.linethrough {
    text-decoration: line-through;
}

.control-key-hide {
    display: none;
    font-size: 60%;
}

// api docs section
.api-docs .section > h2 {
    padding-top: 0.5rem;
    border-top: 1px solid black;
}

.api-docs .section-content {
    padding-left: 2rem;
}

// hacks for subscriber docs
div.class > h3 {
    margin-top: 100px;
}

div.class > h2 + h3 {
    margin-top: inherit;
}

h4 {
    margin-bottom: 0;
    padding-top: 0.5em;
}

h4 method {
    font-size: 115%;
    color: $apiFunctionColor;
    background-color: transparent;
}

h4 arguments {
    color: $apiArgumentColor;
}
