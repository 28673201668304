
pre.highlight {
  padding: $boxPadding;
  margin: $boxMargin;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; // Firefox
  border-radius: $boxRadius;
  font-size: 11pt;
  line-height: 1.5em;
  border-radius: $boxRadius;
  background-color: scale-color(adjust-hue($highlightCodeColor, 10deg), $lightness: 80%);
  border: 1px solid $highlightCodeColor;
}

code.highlighter-rouge {
  background-color: scale-color(adjust-hue($highlightCodeColor, 10deg), $lightness: 80%);
  padding: 1px 3px;
  border-radius: 3px;
}

.language-plaintext pre.highlight {
  color: $highlightConsoleColor;
  background-color: scale-color(adjust-hue($highlightConsoleColor, 10deg), $lightness: 80%);
  border: 1px solid $highlightConsoleColor;
  code {
    background-color: scale-color(adjust-hue($highlightConsoleColor, 10deg), $lightness: 80%);
  }
}

#home .highlight {
  -webkit-filter: grayscale(100%);
  filter: gray;
  filter: grayscale(100%);
  &:hover {
    -webkit-filter: none;
    filter: none;
  }
}

.highlight .c { color: #999 } /* Comment */
.highlight .err { color: red } /* Error */
.highlight .g { color: rgb(54, 54, 54) } /* Generic */
.highlight .k { color: rgb(73, 152, 255) } /* Keyword */
.highlight .l { color: #93A1A1 } /* Literal */
.highlight .n { color: rgb(54, 54, 54) } /* Name */
.highlight .o { color: #aa25ff } /* Operator */
.highlight .x { color: #CB4B16 } /* Other */
.highlight .p { color: #93A1A1 } /* Punctuation */
.highlight .cm { color: #586E75 } /* Comment.Multiline */
.highlight .cp { color: #aa25ff } /* Comment.Preproc */
.highlight .c1 { color: #586E75 } /* Comment.Single */
.highlight .cs { color: #aa25ff } /* Comment.Special */
.highlight .gd { color: #2AA198 } /* Generic.Deleted */
.highlight .ge { color: #93A1A1; font-style: italic } /* Generic.Emph */
.highlight .gr { color: #DC322F } /* Generic.Error */
.highlight .gh { color: #CB4B16 } /* Generic.Heading */
.highlight .gi { color: #aa25ff } /* Generic.Inserted */
.highlight .go { color: #93A1A1 } /* Generic.Output */
.highlight .gp { color: #93A1A1 } /* Generic.Prompt */
.highlight .gs { color: #93A1A1; font-weight: bold } /* Generic.Strong */
.highlight .gu { color: #CB4B16 } /* Generic.Subheading */
.highlight .gt { color: #93A1A1 } /* Generic.Traceback */
.highlight .kc { color: #CB4B16 } /* Keyword.Constant */
.highlight .kd { color: #268BD2 } /* Keyword.Declaration */
.highlight .kn { color: rgb(73, 152, 255) } /* Keyword.Namespace */
.highlight .kp { color: #aa25ff } /* Keyword.Pseudo */
.highlight .kr { color: #268BD2 } /* Keyword.Reserved */
.highlight .kt { color: #DC322F } /* Keyword.Type */
.highlight .ld { color: #c42f07; } /* Literal.Date */
.highlight .m { color: #c42f07; } /* Literal.Number */
.highlight .s { color: #ff05da; } /* Literal.String */
.highlight .na { color: #93A1A1 } /* Name.Attribute */
.highlight .nb { color: #0bd51e; } /* Name.Builtin */
.highlight .nc { color: #ff3ca8 } /* Name.Class */
.highlight .no { color: #ff3ca8 } /* Name.Constant */
.highlight .nd { color: #ff3ca8 } /* Name.Decorator */
.highlight .ni { color: #ff3ca8 } /* Name.Entity */
.highlight .ne { color: #ff3ca8 } /* Name.Exception */
.highlight .nf { color: #ff3ca8 } /* Name.Function */
.highlight .nl { color: #ff3ca8 } /* Name.Label */
.highlight .nn { color: rgb(53, 73, 128) } /* Name.Namespace */
.highlight .nx { color: #555 } /* Name.Other */
.highlight .py { color: #93A1A1 } /* Name.Property */
.highlight .nt { color: #268BD2 } /* Name.Tag */
.highlight .nv { color: #268BD2 } /* Name.Variable */
.highlight .ow { color: #aa25ff } /* Operator.Word */
.highlight .w { color: #93A1A1 } /* Text.Whitespace */
.highlight .mf { color: #c42f07; } /* Literal.Number.Float */
.highlight .mh { color: #c42f07; } /* Literal.Number.Hex */
.highlight .mi { color: #c42f07; } /* Literal.Number.Integer */
.highlight .mo { color: #c42f07; } /* Literal.Number.Oct */
.highlight .sb { color: #ff05da } /* Literal.String.Backtick */
.highlight .sc { color: #ff05da } /* Literal.String.Char */
.highlight .sd { color: #ff05da } /* Literal.String.Doc */
.highlight .s2 { color: #ff05da } /* Literal.String.Double */
.highlight .se { color: #ff05da } /* Literal.String.Escape */
.highlight .sh { color: #ff05da } /* Literal.String.Heredoc */
.highlight .si { color: #ff05da } /* Literal.String.Interpol */
.highlight .sx { color: #ff05da } /* Literal.String.Other */
.highlight .sr { color: #ff05da } /* Literal.String.Regex */
.highlight .s1 { color: #ff05da } /* Literal.String.Single */
.highlight .ss { color: #ff05da } /* Literal.String.Symbol */
.highlight .bp { color: #f29108; } /* Name.Builtin.Pseudo */
.highlight .vc { color: #268BD2 } /* Name.Variable.Class */
.highlight .vg { color: #268BD2 } /* Name.Variable.Global */
.highlight .vi { color: #268BD2 } /* Name.Variable.Instance */
.highlight .il { color: #c42f07 } /* Literal.Number.Integer.Long */