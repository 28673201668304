/* POSITIONING */

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.rightWhenBig {
  text-align: left;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.justify {
  text-align: justify;
}

/* ==== GRID SYSTEM ==== */

.page-wrapper {
  margin-left: auto;
  margin-right: auto;
}

.item {
  padding: 10px 0;
}

@media (min-width: 30em) {
    .row { width: 100%; display: table; table-layout: fixed; }
    .col, footer .col {
        width: auto;
        display: table-cell;
        padding-right: 10px;
    }
    .row .col:last-child {
        padding-right: 0px;
    }
    .rightWhenBig {
        text-align: right;
    }
}

.auto-width {
    table-layout: auto;
}