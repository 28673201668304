//
// VARIABLES
//

// sections
$defaultColor: black;
$documentationColor: hsl(316, 100%, 53%);
$newsColor: hsl(120, 100%, 50%);
$storeColor: hsl(197, 100%, 50%);
$educationColor: hsl(277, 100%, 50%);
$forumColor: $newsColor;
$how-tosColor: $documentationColor;
$building-toolsColor: $documentationColor;
$extensionsColor: $storeColor;

// API docs
$apiClassColor: hsl(0, 100%, 50%);
$apiClassBorderColor: rgb(81, 107, 255);
$apiFunctionColor: hsl(30, 100%, 50%);
$apiAttributeColor: hsl(45, 100%, 50%);
$apiArgumentColor: rgb(203, 200, 219);
$apiDescriptionColor: rgb(185, 5, 255);

// code samples
$highlightCodeColor: hsl(47, 100%, 50%);
$highlightConsoleColor: rgb(73, 152, 255);

// box
$boxPadding: 0.6em;
$boxMargin: 1em 0 1em 0;
$boxRadius: 5px;

// box colors
$noteColor: rgb(170, 170, 170);
$warningColor: rgb(255, 100, 0);
$todoColor: rgb(255, 0, 0);
$seealsoColor: hsl(150, 50%, 50%);
$tipColor: rgb(191, 63, 191);

// info tips
$infoTipColor: hsl(100, 100%, 60%);

// fonts
$font: "RoboType-Roman", Helvetica;
$fontBold: "RoboType-Bold", Helvetica-Bold;
$fontItalic: "RoboType-Italic", Helvetica-Italic;
$fontBoldItalic: "RoboType-BoldItalic", Helvetica-BoldItalic;
$fontIcon: "RoboType-Icons", Helvetica;
$fontNarrow: "RoboType-NarrowBold", Helvetica-Bold;
$fontMono: "RoboType-Mono", "Menlo";
